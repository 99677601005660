<template>
  <div>
    <table class="min-w-full">
      <thead>
        <tr>
          <th
            v-for="(item, key) in headers"
            :key="key"
            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
          >
            {{ $t(item) }}
          </th>
          <th
            class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
          ></th>
        </tr>
      </thead>

      <tbody class="bg-white">
        <tr v-for="(item, index) in content" :key="index">
          <td v-if="content[index]"
            v-for="(it, ind) in headers"
            :key="ind"
            class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
          >
            <div 
              v-if="it == 'type'"
              class="text-xs leading-5 capitalize px-2 font-semibold rounded-full text-gray-900"
            >
              {{ content[index][it] }}
            </div>
            <div v-else-if="it == 'status' && name == 'users'">
              <p>
                <select
                  id="type"
                  name="status"
                  autocomplete="type"
                  v-model="content[index][it]"
                  @change="ChangeStatus(content[index])"
                  class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                >
                  <option selected disabled :value="null">Status</option>
                  <option
                    v-for="(value, index) in status"
                    :key="index"
                    :value="value"
                  >
                    {{ value }}
                  </option>
                </select>
              </p>
            </div>
            <div v-else-if="it == 'countries' && name == 'users'" class="flex">
                 <span v-for="(images, r) in content[index][it]" :key="r">
                        <img :src="images" class="w-4 h-4 rounded-full mr-2">
                  </span>
            </div>

            <div v-else-if="it == 'isConverted' && name == 'subscribers'">
              <div v-if="content[index] && content[index][it]">
                <button
                  class="rounded-full flex items-center text-green-500  focus:outline-none"
                >
                  <i class="material-icons mr-2">check_circle</i
                  ><span>{{$t('converted')}}</span>
                </button>
              </div>

              <div v-else class="flex">
                <button
                  @click="convertUser(content[index])"
                  class="border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none" style="color: rgb(238, 43, 43); border-color: rgb(238, 43, 43);"
                >
                 {{$t('convert')}}
                </button>
              </div>
            </div>

            <div v-else-if="it == 'status' && name == 'orders'">
              <p>
                <select
                  id="type"
                  name="status"
                  autocomplete="type"
                  v-model="content[index][it]"
                  @change="ChangeStatusOrder(content[index])"
                  class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                >
                  <option selected disabled :value="null">{{$t('status')}}</option>
                  <option
                    v-for="(value, index) in statusOrder"
                    :key="index"
                    :value="value"
                  >
                    {{ value.name }}
                  </option>
                </select>
              </p>
            </div>

            <div v-else class="text-sm capitalize leading-5 text-gray-900">
             <div v-if="it == 'role'">
               <span v-if="content[index] && content[index][it]"> {{ content[index][it]['name'] }}</span>
             </div>
              <div v-else-if="it == 'services'">
                    <span v-if="content[index]['oldObject'] && content[index]['oldObject'].settings && content[index]['oldObject'].settings.length>0">
                        <div v-for="(setting, i) in content[index]['oldObject'].settings" :key="i" class="flex flex-col lg:flex-row mb-2 items-center">
                            <img :src="setting.flag" class="w-4 h-4 rounded-full mr-2">
                            <div id="confirmation" style="transform: inherit;" :class="setting.confirmation?'active':''" class="intro-y mr-1 flex-1 box-1 py-1">
                                    <div class="text-smmm font-medium text-center">Confirmation</div>
                                </div>
                                <div style="transform: inherit;" id="Warehouse" :class="setting.fulfillement?'active':''" class="intro-y  mr-1 flex-1 box-1 py-1">
                                    <div class="text-smmm font-medium text-center">Warehouse</div>
                                </div>
                                <div id="Shipping" style="transform: inherit;" :class="setting.shipping?'active':''" class="intro-y flex-1 box-1 py-1">
                                    <div class="text-smmm font-medium text-center">Shipping</div>
                                </div>
                        </div>
                    </span>
             </div>
             <div v-else>
              <span v-if="it == 'date' && name=='subscribers'"> 
                   <span v-if="content[index] && content[index].createdAt"> {{ $moment(content[index].createdAt).format('yyyy-MM-DD HH:mm')}}</span>
              </span>
              <span v-if="it == 'country' && name=='subscribers' && content[index] && content[index][it] && content[index][it].length==2">
                  {{ $f.getCountryNameByCode(options,content[index][it])}}
              </span>
              <span v-else>
                {{ content[index][it] }}  
                 <div v-if="currentUser && currentUser.type=='CEO' && name == 'users' && it=='fullName' && CheckActivite(content[index])" class="w-full font-medium whitespace-nowrap">
                    <div  class="online-indicator"><span  class="blink" :class="CheckActivite(content[index]) == 'Online'?'online':'offline'"></span></div>
                    <h2  class="online-text">{{CheckActivite(content[index])}}</h2>
                </div> 

              </span>
               
              </div>
            </div>
          </td>
          <td
            v-if="name != 'productsOrder'"
            class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
          >
            <div class="flex justify-end">
              <router-link
                v-if="
                  getAccess(name, 'update') &&
                  name != 'statusHistories' &&
                  name != 'subscribers' &&
                  name != 'stockHistories'
                "
                :class="$colors.actionTable"
                class="rounded-full flex justify-center items-center w-8 h-8 mr-3 mt-1"
                :to="`${name}/edit/${content[index]['_id']}`"
              >
                <i class="material-icons mx-2">edit</i>
              </router-link>
              <router-link
                v-if="
                  getAccess(name, 'update') &&
                  name == 'subscribers' &&
                  content[index]['isConverted'] != true
                "
                :class="$colors.actionTable"
                class="rounded-full flex justify-center items-center w-8 h-8 mr-3 mt-1"
                :to="`${name}/edit/${content[index]['_id']}`"
              >
                <i class="material-icons mx-2">edit</i>
              </router-link>

              <button
                v-if="getAccess(name, 'delete')"
                :class="$colors.actionTable"
                @click="remove(content[index]['_id'])"
                class="rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1"
              >
                <i class="material-icons mx-2">delete</i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="content.length <= 0" class="bg-blue-100 py-8">
      <span
        class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
      >
        <p class="font-semibold flex text-green-500  whitespace-no-wrap">
          <i class="material-icons mx-3">error</i>
          <span class="">{{$t('no_rows_selected')}} </span>
        </p>
      </span>
    </div>
      <!--Modal set service seller Shipping -->
             <vue-modal-2  name="ModalChangeSetSettings" :headerOptions="{title: 'Convert user'}" :noFooter="true" modalSize="lg" @on-close="closeModal()">
                <div class="bg-white rounded-lg">

                    <div class="relative">
                        <div class="rounded-lg flex">
                            <div class="w-full p-4 pt-0">
                                <div class="text-base mb-5   font-medium truncate"> Seller: {{subscriber.user.fullName||''}} </div>
                                <div class="change-status border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                            <label for="settings" class="bg-white text-gray-700 px-1">Country (warhousing)</label>
                                        </p>
                                    </div> 
                                   <p>
                                    <v-select @input="setSelectedContry" v-model="subscriber.user.country" :options="warehouses" label="name">
                                        <template slot="option" slot-scope="option">
                                            <img class="flag-img" :src="$f.getCountryCode(option.country)"/>
                                            {{ option.countryName }} 
                                        </template>
                                    </v-select>
                                  </p>
                                </div> 
                                 <!--services -->
                              <div class="flex mt-10 mb-5 items-center text-center">
                                  <hr class="border-gray-300 border-1 w-full rounded-md">
                                  <label class="block font-medium text-sm cursor-pointer text-green-500  w-full"><span class="font-medium text-base text-green-800 dark:text-green-200">Choisir les services</span> </label>
                                  <hr class="border-gray-300 border-1 w-full rounded-md">
                              </div>
                                
                              <div class="flex flex-col lg:flex-row">
                                <div id="confirmation" @click="activate('confirmation')" :class="{active: subscriber.settings.confirmation}" class="border intro-y flex-1 box-1 py-10 cursor-pointer zoom-in">
                                  <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                                  <div class="text-xl font-medium text-center mt-10">Confirmation</div>
                                </div>
                                <div id="fulfillement" @click="activate('fulfillement')" :class="{active: subscriber.settings.fulfillement}" class="border intro-y flex-1 box-1 py-10 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in">
                                    <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                                    <div class="text-xl font-medium text-center mt-10">Warehouse</div>
                                    <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                    <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                                </div>
                                <div id="shipping" @click="activate('shipping')" :class="{active: subscriber.settings.shipping}" class=" border intro-y flex-1 box-1 py-10 lg:ml-5 cursor-pointer zoom-in">
                                    <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                                    <div class="text-xl font-medium text-center mt-10">Shipping</div>
                                    <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                    <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                                </div>
                              </div>
                           </div>
                           </div>    
                          <div class="p-4 flex space-x-4">
                              <a href="#" @click="closeModal()" class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400  font-bold rounded-lg text-sm">Cancel</a>
                              <a href="#" @click="confirmChange" class="w-1/2 px-4 py-3 text-center text-blue-100 bg-blue-600 rounded-lg hover:bg-blue-700 hover:text-white font-bold text-sm">Confirme</a>
                          </div>
                         </div> 
                    
                </div>
            </vue-modal-2>  
  </div>
</template>

<!--  ************  -->
<script>
export default {
  props: {
    headers: { default: [] },
    content: { default: [] },
    name: { type: String, default: "" },
    refresh: { type: Function },
    idWharhouse: { type: Object},
    currentUser: { type: Object},
  },
  data() {
    return {
      filters: [],
      status: ["active", "inactive", "blocked", "deleted"],
      statusOrder: [],
      actions: [],
      subscriber:{user:{country:null},settings:{confirmation:false,fulfillement:false,shipping:false,warehouse:null}},
      options:this.$countries,
      paginate: {
        limit: 0,
        page: 1,
        total: 0,
        current_page: 0,
        lastpage: 0,
      },
      // currentUser: {
      //   role: {},
      // },
      newSeller: {},
      roles: [],
      categories: [],
      warehouses:[]
    };
  },
  async mounted() {
    console.log('currentUser table-------',this.currentUser)
   // await this.getUser();
   
    if(this.currentUser){
      if (this.currentUser.type == "Admin" ||  this.currentUser.type=='CEO') await this.getRoles();
      await this.getStatus();
      await this.getWarehouses();
      await this.getCategories();
      this.refreshChild();
    }
   
  },
  methods: {
    CheckActivite(user){
      if(user.last_activity && user.type == 'Admin'){
            let nowSub=this.$moment(new Date()).subtract(5, 'minutes').format('yyyy-MM-DD HH:mm:ss');
            let activite="";
            activite="Offline "+this.$moment(user.last_activity).fromNow();
            const activitedate=user.last_activity;

            if(new Date(activitedate) > new Date(nowSub)) activite="Online";

            return activite;
        }
    },
    async getWarehouses(){
        const filter = {limit:10};
        const res = await this.$server.search("warehouses",filter);
        if (res.content.results) {
            this.warehouses = res.content.results;
        } else this.warehouses = [];
    },
    activate:function(el){
        this.subscriber.settings[el] = !this.subscriber.settings[el];
    },
    closeModal(){
        this.$vm2.close('ModalChangeSetSettings');
    },
    async setSelectedContry(contry) {
         if(contry){
             if(typeof this.subscriber.user.country === 'object' && this.subscriber.user.country !== null) this.subscriber.user.country = this.subscriber.user.country.countryName;
          }
      },
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    async getRoles() {
      const res = await this.$server.search("roles");
      if (res.content) this.roles = res.content.results;
      else this.roles = [];
    },
    async getCategories() {
      const res = await this.$server.search("categories");
      if (res.content) this.categories = res.content.results;
      else this.categories = [];
    },
    async remove(id) {
      this.$confirm("Are you sure ?").then(async (res) => {
        if (res) {
          const data = await this.$server.delete(this.name, { _id: id });
          //console.log("HIII");
          if (data._id) {
            alert(`${this.name} deleted !`, "success");
            await this.refreshChild();
          }
        }
      });
    },
    async getStatus() {
      const res = await this.$server.search("status");
      if (res.content.results) this.statusOrder = res.content.results;
      else this.statusOrder = [];
    },
    async refreshChild() {
      await this.refresh();
    },
    async ChangeStatus(dataToEdit) {
      dataToEdit.updateIntable=true;
      const data = await this.$server.update("users", dataToEdit);
      if (data._id) {
        alert("User updated", "success");
      }
    },

    async ChangeStatusOrder(dataToEdit) {
      const data = await this.$server.update("orders", dataToEdit);
      if (data._id) {
        alert("Order updated", "success");
      }
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = [];
    },
    async confirmChange(){
      if(!this.subscriber.user.country){
         alert("Country (warhouse) est obligatoir !", "warning");
        return false;
      }
      if(!this.subscriber.settings.confirmation && !this.subscriber.settings.fulfillement && !this.subscriber.settings.shipping){
         alert("Les services est obligatoir", "warning");
        return false;
      }
      this.$confirm("Are you sure you want to convert this subscriber ?").then(
        async (res) => {
          if (res) {
            var count = this.subscriber.user.country.length;
            if(count>2) this.subscriber.user.country = this.$f.getCountryCodeByName(this.options,this.subscriber.user.country);
            const data = await this.$server.convert("subscribers", this.subscriber);
            this.closeModal();
            if (data && data._id) {
              await this.refresh();
              this.subscriber={user:{country:null},settings:{confirmation:false,fulfillement:false,shipping:false,warehouse:null}};
              alert("subscriber converted !", "success");
            } else  alert(data, "warning");
          }
        }
      );
    },
     convertUser(subscriber) {
      this.subscriber.user={};
      this.subscriber.user=subscriber;
      console.log('user country',this.subscriber.user.country)
      if(!this.subscriber.user.country){
         alert("Country (warhouse) est obligatoir pour converti cette user!", "warning");
         return false;
      }
      var count = this.subscriber.user.country.length;
      if(count==2) this.subscriber.user.country = this.$f.getCountryNameByCode(this.options,this.subscriber.user.country);
     // console.log('user country',this.subscriber.user.country)
      this.$vm2.open('ModalChangeSetSettings');
    },

    getAccess(modelToCheck, accessToCheck) {
      const permissions = this.currentUser.role.permissions;

      for (let i in permissions) {
        let model = permissions[i]["model"];
        let access = permissions[i]["access"];

        if (model == modelToCheck) {
          return access[accessToCheck];
        }
      }
    },
  },
};
</script>

<!--  ************  -->
<style scoped>
dialog:not([open]) {
  display: none;
}
.bg-redd{
    background-color: #f3bcbc !important;
}
div.online-indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background-color: #0fcc45;
    border-radius: 50%;
    position: relative;
}
span.blink {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    animation: blink 1s linear infinite;
}

h2.online-text {
    display: inline;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    text-shadow: 0px 3px 6px rgb(150 150 150 / 20%);
    position: relative;
    cursor: pointer;
    vertical-align: top;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    overflow-y: hidden;
    font-size: .8125rem;
    line-height: 1.2308;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    color: #64666A;
    word-wrap: break-word;
}
span.blink .online{
   background-color: #0fcc45;
   opacity: 0.7;
}
span.blink.offline{
    background-color: #e10b0b;
    opacity: 1;
}
</style>